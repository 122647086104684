<template>
    <div>
        <div class="renderer">
            <slot name="Start" />
        </div>
        <div class="renderer">
            <div class="global details">
                <div class="global execution">
                    <div>Execution</div>
                    <div class="note">{{ arrondir(noteGlobale.ballet_execution) }}</div>
                </div>
                <div class="global choreo">
                    <div>Choreo.</div>
                    <div class="note">{{ arrondir(noteGlobale.ballet_choreo) }}</div>
                </div>
            </div>
            <div class="global">{{ arrondir(noteGlobale.ballet_total) }}</div>
        </div>
        <div class="renderer">
            <slot name="Middle">
                <b-button class="nextStep" variant="primary" @click="nextStep">Suivant</b-button>
            </slot>
        </div>
        <div class="renderer" v-if="cfgVerboseNoteSummary">
            <div v-for="judgeNote in notesPerJudges" :key="judgeNote.judge_id">
                <div class="judgeNote">
                    <div class="judgeName">{{ judgeNote.JUDGE.shortname}}</div>
                    <div class="note">{{ arrondir(judgeNote.ballet_total) }}</div>
                    <div class="execution">Exec. : {{ judgeNote.ballet_execution }}</div>
                    <div class="choreo">Choreo. : {{ judgeNote.ballet_choreo}}</div>
                <b-button
                    @click="modifyJudgeNote(judgeNote.judge_id)"
                    size="sm"
                >
                    Modifier
                </b-button>
                </div>
            </div>
        </div>
        <div class="renderer">
            <slot name="End" />
        </div>
    </div>
</template>

<script>
import { RST_COMPETITOR_NOTES } from "@/LocalDB";

export default {
    props: {
    },
    data(){
        return {
            noteGlobale: '',
            notesPerJudges: Array,
        };
    },
    computed:{
        //...mapGetters('currentState', ['currentCategory', 'currentCompetitor', 'currentFigure', 'currentEvent'] ),
    },
    methods:{
        refresh(){
            this.noteGlobale = RST_COMPETITOR_NOTES.query()
                                .where("competition_id", this.currentEvent.competition_id)
                                .where("round_number", this.currentEvent.round_number)
                                .where("level", this.currentEvent.level)
                                .where('category', this.currentEvent.category)
                                .where('competitor_id', this.currentEvent.current_competitor_id)
                                .first();
/*            this.notesPerJudges = [];
            this.currentEvent.JUDGES.forEach((staff)=> {
                var note = balletNotes.filter(w => w.judge_id === staff.staff_id)[0];
                    if(note != null){
                        this.notesPerJudges.push(note);
                    }
            });*/
        },
        modifyJudgeNote(judgeId){
            this.$emit("modifyJudgeNote", judgeId);
        },
        nextStep(){
            this.$emit("nextStep");
        },
    },
    mounted(){
        this.refresh();
    }
}
</script>

<style scoped>
    .renderer{
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .global{
        display:flex;
        justify-content:center;
        align-items:center;
        font-size:2.5em; border:solid 1px black; color:black; font-weight: bold; width:130px; height:90px;
    }
    .global.details{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .global.execution, .global.choreo{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 45px;
        font-size:0.25em;
        border:none;
        font-weight: lighter;
    }
    .global.execution > .note, .global.choreo > .note{
        font-size:2.5em;
        font-weight: lighter;
    }
    .global.choreo {
        border-top:solid 1px black;
    }
    .judgeNote{
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        align-items: center;
        /*flex-wrap: wrap;*/
        font-size:0.8em; border: solid 1px darkgray; color:darkgray; width:100px; height:80px; margin: 20px 10px;
        padding: 2px 0px;
    }
    .judgeName{
        font-style: italic; font-size:0.7em;
    }
    .note{
        font-weight: bold;
    }
    .nextStep{
        font-size:2.5em;
            /*border:solid 4px darkgreen;*/
            /*background-color:lightgreen; color:darkgreen;*/
        margin: 20px 0px;
        padding:4px;
    }
    .modifyJudgeNote{
        font-size:0.7em;
        margin: 2px;
    }

</style>
